import { Route, Routes } from "react-router-dom";
import ImagePage from "./components/gallery/ImagePage";
import Welcome from "./components/welcome/Welcome";
import axios from "axios";

const AppRoutes = () => (
    <Routes>
        <Route path={'/'} element={<Welcome/>}/>
        <Route path={'/gallery'} element={<ImagePage pictureFileNames={getPictures()}/>}/>
    </Routes>
);

async function getPictures(): Promise<string[]> {
    try {
        const { data, status } = await axios.get(
            '/pics',
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Accept: 'text/html',
                }
            }
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [error.message];
        } else {
            console.log('unexpected error: ', error);
            return ['An unexpected error occurred'];
        }
    }
}

const bla = getPictures()

export default AppRoutes;