import React from 'react';
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./AppRoutes";

function App() {

  return (
     <div className="App">
         <Router>
           <header className="App-header">
             <AppRoutes></AppRoutes>
           </header>
         </Router>
     </div>
  );
}

export default App;
