import React, {useEffect, useState} from 'react';
import btnBack from '../../img/icon_back.svg';
import btnForward from '../../img/icon_forward.svg';
import btnDownload from '../../img/icon_download.svg';
import {useTranslation} from "react-i18next";

export interface ImagePageProps {
    pictureFileNames: Promise<string[]>
}

const ImagePage = ( { pictureFileNames }: ImagePageProps ) => {
    const { t } = useTranslation();
    const [apiImgIndex, setApiImgIndex] = useState<number>(0)
    const [apiImgFileNames, setApiImgFileNames] = useState<string[]>([])

    useEffect(() => {
        pictureFileNames.then((allPics) => {
            setApiImgFileNames(allPics)
        })
        nextImage(apiImgIndex)
    },[])

    function nextImage(nextIndex: number) {
        if (nextIndex > (apiImgFileNames.length -1)) {
            setApiImgIndex(0)
        } else if (nextIndex < 0) {
            setApiImgIndex((apiImgFileNames.length - 1))
        } else {
            setApiImgIndex(nextIndex)
        }
    }

    function previous() {
        nextImage(apiImgIndex - 1)
    }

    function next() {
        nextImage(apiImgIndex + 1)
    }

    function stringValue(value : number) {
        if (value < 10) {
            return '0' + value
        } else {
            return value.toString()
        }
    }

    const handleSubmit = () => {

    }

    return (
        <div className={""}>
            <img className={"pictureSize"} src={'../../gallery/800_' + apiImgFileNames[apiImgIndex]} alt={"gallery item"} />
            <div className={`displayTable fullWidth`}>
                <form className={`displayTableCell alignRight`}>
                        <input
                            className={`inputField`}
                            type="number"
                            value={apiImgIndex + 1}
                            min={1}
                            max={apiImgFileNames.length}
                            onChange={e => nextImage(parseInt(e.target.value) - 1)}
                        />
                </form>
                <div className={`displayTableCell alignLeft yellowText`}> / {apiImgFileNames.length}</div>
                <div>
                    <a  href={'../../gallery/raw/' + apiImgFileNames[apiImgIndex]} download>
                        <button className={"menuButton noPadding"}>
                            <img className={"iconWidth"} src={btnDownload} alt={t('down')} title={t('downloadRawPic')} />
                        </button>
                    </a>
                </div>
            </div>
            <div className={`displayTableCell yellowText`}>
                <button className={"menuButton btnPositionGallery left"} onClick={previous}>
                    <img className={"iconSize"} src={btnBack} alt={"back"} />
                </button>
            </div>
            <div>
                <button className={"menuButton btnPositionGallery right"} style={{zIndex: '999'}} onClick={next}>
                    <img className={"iconSize"} src={btnForward} alt={"forward"} />
                </button>
            </div>
        </div>
    );
};

export default ImagePage;